<header class="header">
  <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="container navbar navbar-expand-md px-0">
    <div class="d-flex flex-grow-1 header-data-pc">
      <a class="navbar-brand m-2" routerLink="https://www.ust.hk" >
        <img src="assets/images/HKUST-logo.png" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>

<div class="site-profile" >
                                                <div class="site-info">
                                                        <div class="site-logo">
                                                                <a href="https://www.ust.hk/" target="_blank" title="The Hong Kong University of Science and Technology" previewlistener="true"><img alt="The Hong Kong University of Science and Technology" src="https://library.hkust.edu.hk/wp-content/themes/hkustlib/hkust_alignment/profiles/ust/modules/custom/hkust_signature_affiliate/assets/images/HKUST-logo.png"></a>
                                                        </div>
                                                        <div class="school-name">
                                                                <a href="https://library.hkust.edu.hk" previewlistener="true"><img id="lib_logo" alt="HKUST Library" src="https://library.hkust.edu.hk/wp-content/themes/hkustlib/hkust_alignment/core/assets/library/library_logo.png_transparent_bkgd_h300.png" width="" ></a>
                                                        </div>

                                                        <div class="school-name" style="display:none;">
                                                                <a href="/" previewlistener="true">LIBRARY</a>
                                                        </div>
                                                </div>
                                        </div>

    </div>
    <div class="navbar-buttons d-flex flex-grow-1 ml-auto justify-content-end align-items-center gapx-1">
      <ds-themed-search-navbar></ds-themed-search-navbar>
      <ds-themed-lang-switch></ds-themed-lang-switch>
      <ds-context-help-toggle></ds-context-help-toggle>
      <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar></ds-impersonate-navbar>
      <div class="pl-2">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                aria-controls="collapsingNav"
                aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
          <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </nav>
  <ds-themed-navbar></ds-themed-navbar>

</header>
