<ds-themed-loading *ngIf="(loading$ | async)"></ds-themed-loading>
<div *ngIf="!(loading$ | async)">
  <span class="dropdown-item-text" [class.pl-0]="inExpandableNavbar">
    {{dsoNameService.getName(user$ | async)}}<br>
    <span class="text-muted">{{(user$ | async)?.email}}</span>
  </span>
  <div class="dropdown-divider"></div>
  <ds-log-out *ngIf="!inExpandableNavbar" data-test="log-out-component"></ds-log-out>
</div>


