<nav [ngClass]="{'open': !(menuCollapsed | async)}" [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
  class="navbar navbar-expand-md navbar-light p-0 navbar-container" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <div class="navbar-inner-container w-100 h-100 " [class.container]="!(isXsOrSm$ | async)">
    <a class="navbar-brand my-2" routerLink="https://www.ust.hk" style="display:none;">
	<img src="assets/images/HKUST-logo.png" [attr.alt]="'menu.header.image.logo' | translate"/>
    </a>

<div class="header-data-pc site-profile abc" style="">
						<div class="site-info">
							<div class="site-logo">
								<a href="https://www.ust.hk/" target="_blank" title="The Hong Kong University of Science and Technology" previewlistener="true"><img alt="The Hong Kong University of Science and Technology" src="https://library.hkust.edu.hk/wp-content/themes/hkustlib/hkust_alignment/profiles/ust/modules/custom/hkust_signature_affiliate/assets/images/HKUST-logo.png" style=""></a>
							</div>
							<div class="school-name">
                        					<a href="https://library.hkust.edu.hk" previewlistener="true"><img id="lib_logo" alt="HKUST Library" src="https://library.hkust.edu.hk/wp-content/themes/hkustlib/hkust_alignment/core/assets/library/library_logo.png_transparent_bkgd_h300.png" width="" height="83" style=""></a>
                    					</div>

							<div class="school-name" style="display:none;">
								<a href="/" previewlistener="true">LIBRARY</a>
							</div>
						</div>
					</div>

    <div id="collapsingNav" class="w-100 h-100">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 h-100">
        <li *ngIf="(isXsOrSm$ | async) && (isAuthenticated$ | async)">
            <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
        </li>
        <li *ngFor="let section of (sections | async)">
          <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
        </li>
      </ul>
    </div>
    <div class="navbar-buttons d-flex align-items-center gapx-1">
      <ds-themed-search-navbar class="navbar-collapsed"></ds-themed-search-navbar>
      <ds-themed-lang-switch class="navbar-collapsed"></ds-themed-lang-switch>
      <ds-context-help-toggle class="navbar-collapsed"></ds-context-help-toggle>
      <ds-themed-auth-nav-menu class="navbar-collapsed"></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
    </div>
  </div>
</nav>
